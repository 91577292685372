.nav {
  position: relative;
  /* height: 100px; */
  top: 0;
  left: 0;
  padding: 1rem 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(0, 0, 0, 0.3); */
  z-index: 5;
}

.hamburger {
  display: none;
}

.nav h3 {
  font-size: 3rem;
}

.links {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7rem;
}

.link {
  text-decoration: none;
  color: white;
  transition: 0.3s;
}

.link:hover {
  transform: translateY(-0.5rem);
  transition: 0.3s;
  color: #ffd60a;

  cursor: pointer;
}

@media (max-width: 1200px) {
  .nav h3 {
    font-size: 2.5rem;
  }

  .links {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
}

@media (max-width: 1050px) {
  .nav {
    position: sticky;

    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0rem 0rem;
    z-index: 5;
    /* background-color: black; */
  }
  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 100;
  }

  .hamburger :hover {
    background-color: #ffd60a;
    transition: 0.3s;
  }

  .opened-hamburger1 {
    width: 55px;
    height: 5px;
    background-color: black;
    margin: 5px;
    transition: 0.3s;
    transform: rotate(40deg);
  }

  .opened-hamburger2 {
    display: none;
    width: 30px;
    height: 3px;
    background-color: black;
    margin: 5px;
    transition: 0.3s;
  }

  .opened-hamburger3 {
    width: 50px;
    height: 5px;
    background-color: black;
    margin: 5px;
    transition: 0.3s;
    transform: rotate(-55deg);
  }

  .line {
    width: 30px;
    height: 3px;
    background-color: aliceblue;
    margin: 5px;
    transition: 0.3s;
  }

  .link {
    color: black;
    font-size: 2.5rem;
  }

  .link:hover {
    transform: translateX(-1rem);
    transition: 0.3s;
    color: white;
    cursor: pointer;
  }

  .links {
    position: absolute;
    left: 0rem;
    top: 0rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    background-color: black;
    height: 100vh;
    width: 100%;
    pointer-events: none;
    clip-path: circle(100px at 90% -10%);
    -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 1s ease-out;
  }

  .open {
    position: absolute;
    left: 0rem;
    top: 0rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: #f6cf0a;
    height: 100vh;
    width: 100vw;
    pointer-events: all;
    clip-path: circle(1300px at 90% -10%);
    -webkit-clip-path: circle(1300px at 90% -10%);
    transition: all 1s ease-out;
  }
}

@media (max-width: 588px) {
  .nav h3 {
    font-size: 2rem;
  }
}

@media (max-width: 380px) {
  .nav h3 {
    font-size: 1.5rem;
  }
}
