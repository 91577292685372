.container {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about h2 {
  font-size: 4rem;
  border-bottom: 3px solid #ffd60a;
  padding-bottom: 10px;
}

.about p {
  font-size: 2.5rem;
  width: 800px;
  margin-top: -1.5rem;
}

@media (max-width: 1560px) {
  .about p {
    font-size: 2.5rem;
  }
}

@media (max-width: 1005px) {
  .container {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about h2 {
    font-size: 3rem;
  }
  .about p {
    font-size: 2.1rem;
    width: 700px;
  }
}

@media (max-width: 780px) {
  .container {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about h2 {
    font-size: 2.5rem;
  }
  .about p {
    font-size: 1.5rem;
    width: 500px;
    margin-top: -1rem;
  }
}

@media (max-width: 600px) {
  .about h2 {
    font-size: 2rem;
  }
}

@media (max-width: 484px) {
  .container {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about h2 {
    font-size: 2rem;
  }
  .about p {
    font-size: 1.3rem;
    width: 300px;
    margin-top: -1rem;
  }
}
