.container {
  width: 100%;
  height: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header h2 {
  font-size: 4rem;
  border-bottom: 3px solid #ffd60a;
  padding-bottom: 10px;
}

.header p {
  font-size: 2rem;
  width: 50vw;
  margin-top: -1.5rem;
  color: rgba(255, 255, 255, 0.42);
}

.container main {
  padding: 2rem;
}

.technology-container {
  display: flex;
  flex-wrap: wrap;
}

.technology {
  flex: 1 1 20%;
  width: 50px;
  margin: 0 3rem 4rem 3.5rem;
  border: 3px solid #ffd60a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.technology p {
  font-size: 1.4rem;
  font-weight: 800;
  letter-spacing: 2px;
}

@media (max-width: 1265px) {
  .header p {
    font-size: 1.5rem;
    width: 50vw;
    margin-top: -1.5rem;
    color: rgba(255, 255, 255, 0.42);
  }
}

@media (max-width: 1000px) {
  .header h2 {
    font-size: 3rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .header p {
    font-size: 1.5rem;
    width: 60vw;
    margin-top: -1.5rem;
    color: rgba(255, 255, 255, 0.42);
  }

  .technology {
    flex: 1 1 20%;
    width: 40px;
    margin: 0 2rem 3rem 2.5rem;
    border: 3px solid #ffd60a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  .technology p {
    font-size: 1.2rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
}

@media (max-width: 700px) {
  .header h2 {
    font-size: 2.5rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .header p {
    font-size: 1.2rem;
    width: 60vw;
    margin-top: -1.5rem;
    color: rgba(255, 255, 255, 0.42);
  }

  .technology {
    flex: 1 1 20%;
    width: 30px;
    margin: 0 2rem 2rem 2.5rem;
    border: 3px solid #ffd60a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  .technology p {
    font-size: 1.1rem;
    font-weight: 800;
    letter-spacing: 3px;
  }
}

@media (max-width: 600px) {
  .header h2 {
    font-size: 2rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .header p {
    font-size: 1.1rem;
    width: 70vw;
    margin-top: 0rem;
    color: rgba(255, 255, 255, 0.42);
  }

  .technology {
    flex: 1 1 20%;
    width: 40px;
    margin: 0 2rem 2rem 2.5rem;
    border: 3px solid #ffd60a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  .technology p {
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 3px;
  }
}

@media (max-width: 465px) {
  .header h2 {
    font-size: 1.6rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .header p {
    font-size: 1rem;
    width: 70vw;
    margin-top: 0rem;
    color: rgba(255, 255, 255, 0.42);
  }

  .technology {
    flex: 2 0 30%;
    width: 50px;
    margin: 0 3rem 1rem 3rem;
    border: 2px solid #ffd60a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  .technology p {
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 3px;
  }
}

@media (max-width: 365px) {
  .header h2 {
    font-size: 1.4rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .header p {
    font-size: 1rem;
    width: 70vw;
    margin-top: 0rem;
    color: rgba(255, 255, 255, 0.42);
  }

  .technology {
    flex: 2 0 30%;
    width: 50px;
    margin: 0 3rem 1rem 3rem;
    border: 2px solid #ffd60a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }

  .technology p {
    font-size: 0.8rem;
    font-weight: 800;
    letter-spacing: 3px;
  }
}
