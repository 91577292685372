.container {
  padding: 5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.img-link_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.button-container img:hover {
  cursor: pointer;
}

.container img {
  width: 45rem;
  height: 25rem;
  border-radius: 20px;
  /* opacity: 0.8; */
}

.project-info_container {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 30%;
}

.project-info_container h4 {
  color: rgba(255, 255, 255, 0.42);
  font-size: 1.3rem;
  margin-bottom: -1.8rem;
}
.project-info_container h3 {
  font-size: 2.5rem;
  border-bottom: 3px solid #ffd60a;
}

.project-technologies_container {
  width: 100%;
  display: flex;

  align-items: center;
  gap: 1rem;
}

.project-technologies_container p {
  font-size: 1.4rem;
  font-weight: 800;
  margin-top: -1.5rem;
}

.info {
  background-color: rgba(255, 214, 10, 1);
  padding: 0.5rem 1rem;
}

.info p {
  /* color: white; */
  color: black;
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 500;
}

@media (max-width: 1765px) {
  .project-technologies_container p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1542px) {
  .container img {
    width: 40rem;
    height: 25rem;
  }

  .project-info_container {
    width: 35%;
  }
}

@media (max-width: 1336px) {
  .container img {
    width: 30rem;
    height: 20rem;
  }

  .project-info_container {
    width: 35%;
  }

  .project-technologies_container p {
    font-size: 1.1rem;
  }

  .info p {
    /* color: white; */
    color: black;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 500;
  }
}

@media (max-width: 1238px) {
  .container img {
    width: 30rem;
    height: 20rem;
  }

  .project-info_container {
    width: 40%;
  }

  .project-technologies_container p {
    font-size: 1.1rem;
  }

  .info p {
    /* color: white; */
    color: black;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 500;
  }
}
/* ------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------- */

@media (max-width: 1093px) {
  .container {
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .img-link_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .container img {
    width: 45rem;
    height: 25rem;
    border-radius: 20px;
    /* opacity: 0.8; */
  }

  .project-info_container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
  }

  .project-info_container h4 {
    color: rgba(255, 255, 255, 0.42);
    font-size: 1.3rem;
    margin-bottom: -1.8rem;
  }
  .project-info_container h3 {
    font-size: 2.5rem;
    border-bottom: 3px solid #ffd60a;
  }

  .project-technologies_container {
    width: 100%;
    display: flex;

    align-items: center;
    gap: 1rem;
  }

  .project-technologies_container p {
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: -1.5rem;
  }

  .info {
    background-color: rgba(255, 214, 10, 1);
    padding: 0.5rem 1rem;
  }

  .info p {
    /* color: white; */
    color: black;
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 500;
  }
}

@media (max-width: 832px) {
  .container {
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .container img {
    width: 40rem;
    height: 20rem;
    border-radius: 20px;
  }

  .project-info_container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
  }

  .project-info_container h4 {
    color: rgba(255, 255, 255, 0.42);
    font-size: 1.3rem;
    margin-bottom: -1.8rem;
  }

  .project-info_container h3 {
    font-size: 2.5rem;
    border-bottom: 3px solid #ffd60a;
  }

  .project-technologies_container {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  .project-technologies_container p {
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: -1.5rem;
  }

  .info {
    background-color: rgba(255, 214, 10, 1);
    padding: 0.5rem 1rem;
  }

  .info p {
    /* color: white; */
    color: black;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 500;
  }
}

@media (max-width: 690px) {
  .container img {
    width: 30rem;
    height: 20rem;
  }
  .project-info_container {
    width: 80%;
  }
  .project-info_container h4 {
    color: rgba(255, 255, 255, 0.42);
    font-size: 1.1rem;
    margin-bottom: -1.8rem;
  }

  .project-info_container h3 {
    font-size: 2.2rem;
    border-bottom: 3px solid #ffd60a;
  }

  .project-technologies_container p {
    font-size: 1.1rem;
    font-weight: 800;
    margin-top: -1.5rem;
  }
}

@media (max-width: 571px) {
  .container {
    padding: 3rem 0rem;
  }
  .container img {
    width: 25rem;
    height: 17rem;
  }
  .project-info_container {
    width: 85%;
  }
  .project-info_container h4 {
    color: rgba(255, 255, 255, 0.42);
    font-size: 1rem;
    margin-bottom: -1rem;
  }

  .project-info_container h3 {
    font-size: 1.5rem;
    border-bottom: 3px solid #ffd60a;
  }

  .project-technologies_container {
    gap: 0.7rem;
  }

  .project-technologies_container p {
    font-size: 0.9rem;
    font-weight: 800;
    margin-top: -0.5rem;
  }

  .info {
    padding: 0.5rem 1rem;
  }

  .info p {
    font-size: 0.9rem;
    line-height: 1.4;
    font-weight: 500;
  }
}

@media (max-width: 439px) {
  .container img {
    width: 19rem;
    height: 15rem;
  }
  .project-info_container {
    width: 90%;
  }
  .project-info_container h4 {
    color: rgba(255, 255, 255, 0.42);
    font-size: 1rem;
    margin-bottom: -1rem;
  }

  .project-info_container h3 {
    font-size: 1.4rem;
    border-bottom: 3px solid #ffd60a;
  }

  .project-technologies_container {
    gap: 0.5rem;
  }

  .project-technologies_container p {
    font-size: 0.8rem;
    font-weight: 800;
    margin-top: -0.5rem;
  }

  .info {
    padding: 0.5rem 1rem;
  }

  .info p {
    font-size: 0.8rem;
    line-height: 1.4;
    font-weight: 500;
  }
}
