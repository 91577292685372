.projects-container {
  width: 100%;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title h2 {
  font-size: 4rem;
  border-bottom: 3px solid #ffd60a;
  padding-bottom: 10px;
}

@media (max-width: 1093px) {
  .title h2 {
    font-size: 3rem;
  }
}

@media (max-width: 690px) {
  .title h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 539px) {
  .title h2 {
    font-size: 2rem;
  }
}

@media (max-width: 440px) {
  .title h2 {
    font-size: 1.5rem;
  }
}
