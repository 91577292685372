.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  gap: 10rem;
  margin: 0 auto;
  padding: 5rem 2rem;
  height: 650px;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: -2.5rem;
}

.information h2 {
  font-size: 4rem;
  border-bottom: 3px solid #ffd60a;
  padding-bottom: 10px;
}

.brief {
  margin-top: -2rem;
}

.brief p {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.62);
}

.links-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: -1.5rem;
}

.link img {
  width: 2.5rem;
}

.link a {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  transition: 0.5s;
  text-decoration: none;
  color: white;
}

.link a:hover {
  cursor: pointer;
  color: #ffd60a;
  transition: 0.5s;
}

@media (max-width: 1322px) {
  .contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    gap: 5rem;
    margin: 0 auto;
    padding: 5rem 2rem;
    height: 650px;
  }

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: -2.5rem;
  }

  .information h2 {
    font-size: 3rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }

  /* .brief {
  margin-top: -4rem;
} */

  .brief p {
    font-size: 1.7rem;
    color: rgba(255, 255, 255, 0.62);
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: -1.5rem;
  }

  .link img {
    width: 2rem;
  }

  .link a {
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: 0.5s;
  }
}

@media (max-width: 1000px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 5rem;
    margin: 0 auto;
    padding: 5rem 1rem;
    height: 650px;
  }

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    width: 100%;
  }
  .information h2 {
    font-size: 2.5rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .brief {
    margin-top: -2rem;
  }

  .brief p {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.62);
    text-align: center;
  }

  .links-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 1.5rem;
    width: 100%;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: -1.5rem;
  }

  .link img {
    width: 2rem;
  }

  .link a {
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: 0.5s;
  }
}

@media (max-width: 696px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 5rem;
    margin: 0 auto;
    padding: 5rem 1rem;
    height: 650px;
  }

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    width: 100%;
  }
  .information h2 {
    font-size: 2.3rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .brief {
    margin-top: -2rem;
  }

  .brief p {
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.62);
    text-align: center;
  }

  .links-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 1.5rem;
    width: 100%;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: -1.5rem;
  }

  .link img {
    width: 1.5rem;
  }

  .link a {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: 0.5s;
  }
}

@media (max-width: 536px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 5rem;
    margin: 0 auto;
    padding: 5rem 1rem;
    height: 650px;
  }

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    width: 100%;
  }
  .information h2 {
    font-size: 2rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .brief {
    margin-top: -2rem;
  }

  .brief p {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.62);
    text-align: center;
  }

  .links-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: -1.5rem;
  }

  .link img {
    width: 1.3rem;
  }

  .link a {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: 0.5s;
  }
}

@media (max-width: 536px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 5rem;
    margin: 0 auto;
    padding: 5rem 1rem;
    height: 650px;
  }

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    width: 100%;
  }
  .information h2 {
    font-size: 2.5rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .brief {
    margin-top: -2rem;
  }

  .brief p {
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.62);
    text-align: center;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 100%;
  }

  .link {
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: -1.5rem;
  }

  .link img {
    width: 2rem;
  }

  .link a {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: 0.5s;
  }
}

@media (max-width: 439px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 5rem;
    margin: 0 auto;
    padding: 5rem 1rem;
    height: 650px;
  }

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    width: 100%;
  }
  .information h2 {
    font-size: 1.8rem;
    border-bottom: 3px solid #ffd60a;
    padding-bottom: 10px;
  }
  .brief {
    margin-top: -1rem;
  }

  .brief p {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.62);
    text-align: center;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    width: 100%;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: -1.5rem;
  }

  .link img {
    width: 2rem;
  }

  .link a {
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: 0.5s;
  }
}
